$(document).ready(function(){



	
	/* html5 images en background sur les classes .cover */
	$(".cover").each(function( index ) {
		if(	$(this).attr("data-image")	){
			var image = $(this).data("image");
			$(this).css("background-image","url("+image+")");
		
		}
	});


	Materialize.updateTextFields();
	$('.tooltipped').tooltip({delay: 50});
	$('select').material_select();
	$(".dropdown-button").dropdown();
	
	$('.modal').modal();
	$('.materialboxed').materialbox();
	
	/*
	forms
	*/
	if($(".ajaxForms" ).length>0){
		
		/* validate rules */
		var ruless = [];
		$( ".validate" ).each(function( index ) {
				var name = $(this).attr("name");
				
				ruless[name] = {required: true};
		});
		
		/* validate go! */
		$("#formprojet").validate({
		   rules: ruless,
		   //For custom messages
		   
		   errorElement : 'div',
		   errorPlacement: function(error, element) {
		     var placement = $(element).data('error');
			 if(element.is("select")) {
			 	$(element).parent('div').children("input").addClass('error');
			 	$(".response").addClass('error');
			 }else{
			 	$(".response").addClass('error');
			 }
		     
		   }
		});
		
		
		
		/* send to ajax */	
		   $(".ajaxForms").submit(function(e){
		       e.preventDefault();
			   Materialize.updateTextFields();
			  
		   	
		       var form = $(this);
			   var cible= $(this).attr("action");
	
		            $.post(form.attr('action'), form.serialize(), function(data){
		               //var data = $.parseJSON(data);
	
			                if(data.status === true){
			                   console.log("ok");
	
			                }else{
			                	  console.log(data.status);
			                }
	
	
		            });
		        return false;
		   });
		   
		   
	 }

	
	



});





